import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

import { Layout } from '@/components/layouts/Layout'
import { Seo } from '@/components/Seo'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { ListItems } from '@/components/layouts/ListItems'
import { Button } from '@/components/elements/Button'
import { MicrocmsFaqConnection } from '@/interfaces/graphqlTypes'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { contactFaqList } from '@/constants/contactFaq'

export const query = graphql`
  query {
    allMicrocmsFaq(
      filter: {
        faqId: {
          in: [
            "banklist"
            "zyvxq9czypnq"
            "rnn33zskun4"
            "wa6uax9bgds"
            "chargerule"
          ]
        }
      }
    ) {
      edges {
        node {
          question
          faqId
          mediumCategory {
            id
          }
        }
      }
    }
  }
`

type Query = {
  allMicrocmsFaq: MicrocmsFaqConnection
}

const contactForm = [
  {
    name: '携帯電話を紛失した場合',
    link: '/contact/lost/',
  },
  {
    name: '電話番号の変更がある場合',
    link: '/contact/phone-change/',
  },
  {
    name: '不正があって補償の申請がある場合',
    link: '/contact/compensation/',
  },
  {
    name: '手元にアプリがなくて解約を行いたい場合',
    link: '/contact/cancel/',
  },
]

export default function contact({ data }: { data: Query }): JSX.Element {
  const breadCrumbs: {
    name: string
    path?: string
  }[] = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'お問い合わせ',
      path: '/contact/',
    },
  ]

  data.allMicrocmsFaq.edges.sort((pre, next) => {
    return (
      contactFaqList.indexOf(pre.node.faqId!) -
      contactFaqList.indexOf(next.node.faqId!)
    )
  })

  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        canonical="https://su-pay.jp/contact/"
        description="SU-PAYについてのお問い合わせはこちら。"
      />
      <Title as="h1" bg={true}>
        お問い合わせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            お問い合わせフォーム
          </Title>
        </TitleWrapper>
        <ul>
          {contactForm.map((form) => (
            <ListItems
              key={form.name}
              link={form.link}
              item={form.name}
              showBadge={false}
            />
          ))}
        </ul>
        <TitleWrapper pcMarginBottom={50}>
          <Title as="h2" color={theme.colors.supayBlue}>
            よくあるご質問
          </Title>
        </TitleWrapper>
        <ul>
          {data.allMicrocmsFaq.edges.map((faq) => (
            <ListItems
              key={faq.node.faqId!}
              link={`/faq/${faq.node.mediumCategory?.id!}/${faq.node.faqId!}/`}
              item={faq.node.question!}
            />
          ))}
        </ul>
        <LinkButton>
          <Link to={'/faq/'}>
            <Button showArrowRight>よくあるご質問一覧へ</Button>
          </Link>
        </LinkButton>
        <TitleWrapper>
          <BeforeTitle>
            上記以外のお問い合わせは以下のコールセンターにお問い合わせください。
          </BeforeTitle>
          <Title as="h2" color={theme.colors.supayBlue}>
            お問い合わせ先
          </Title>
        </TitleWrapper>
        <ContactInfo>
          <div>
            <p>株式会社SU-PAY</p>
            <Phone>電話：0570-078-135</Phone>
          </div>
          <p>受付時間：24時間</p>
          <p>※通話料はお客様負担です。</p>
        </ContactInfo>
      </MainWrapper>
    </Layout>
  )
}

const LinkButton = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 60px;

  @media screen and (max-width: 1047px) {
    justify-content: center;
    margin-top: 30px;
  }
`

const BeforeTitle = styled.p`
  margin-bottom: 20px;

  @media screen and (max-width: 1047px) {
    margin-bottom: 10px;
  }
`

const ContactInfo = styled.div`
  font-size: 18px;

  div {
    margin: 80px 0 50px;
    line-height: 1;
  }

  @media screen and (max-width: 1047px) {
    font-size: 14px;

    div {
      margin: 22px 0;
    }
  }
`

const Phone = styled.p`
  font-size: 27px;
  font-weight: bold;
  margin-top: 26px;

  @media screen and (max-width: 1047px) {
    font-size: 18px;
    margin-top: 14px;
  }
`
